/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import { graphql, useStaticQuery } from 'gatsby'

import Helmet from 'react-helmet'
import React from 'react'

export const SEO: React.FC<{ description?: string; title: string; image?: string }> = ({ image, description, title }) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description
  const hackedDescription = metaDescription.replace('My 40th Birthday Wish June', 'My 40th Birthday Wish: June')
  return (
    <Helmet
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={[
        {
          name: `description`,
          content: hackedDescription
        },
        {
          property: `og:title`,
          content: title
        },
        {
          property: `og:description`,
          content: hackedDescription
        },
        {
          property: `og:image`,
          content: image ?? `https://www.maxcantor.com/headshot-300.jpg`
        },
        {
          property: `og:type`,
          content: `website`
        },
        // {
        //   name: `twitter:card`,
        //   content: `summary`
        // },
        // {

        //   name: `twitter:creator`,
        //   content: site.siteMetadata.author
        // },
        {
          name: `twitter:title`,
          content: title
        },
        {
          name: `twitter:description`,
          content: hackedDescription
        }
      ]} // .concat(meta)}
    />
  )
}
