/* eslint-disable prettier/prettier */
/* eslint-disable react/no-danger */
import '../styles/blog.css'
import 'prismjs/themes/prism-twilight.css'

import * as React from 'react'

import { BlogPostTemplateQuery } from '../../graphql-types'
import BlogSidebar from '../components/BlogSidebar'
// import { Helmet } from 'react-helmet'
import { SEO } from '../components/Seo'
import { graphql } from 'gatsby'

// declare global {
//   interface Window {
//     commento?: { main?: Function }
//   }
// }

const PageTemplate: React.FC<{ data: BlogPostTemplateQuery }> = ({ data }) => {
  const mdData = data.markdownRemark
  const { tableOfContents } = mdData || {}
  // React.useEffect(() => {
  //   window.commento?.main?.()
  // }, [])
  const { image, title, subtitle, draft } = data.markdownRemark?.frontmatter || {}

  if (image) {
    return (
      // <IndexLayout>
      <div className="blog">
        <SEO
          image={data.markdownRemark?.frontmatter?.image ?? undefined}
          title={data.markdownRemark?.frontmatter?.title ?? ''}
          description={data.markdownRemark?.excerpt ?? '  '}
        />
        <header className="hero">
          <div style={{ background: `url('${image}')`, backgroundSize: 'cover', backgroundPosition: 'center' }} className="img-placeholder">
            {/* <div className="">hi</div> */}
          </div>
          <div className="hero-content">
            <h1 className="-mt-6 text-center leading-tight px-6">{title}</h1>
            <h2 className="-mt-6 text-center leading-tight italic px-6">{subtitle}</h2>
          </div>
        </header>
        <div className="sidebar-and-article">
          <BlogSidebar>
            <p className="leading-tight hidden sm:block">Contents:</p>
            <div className="toc">
              <div dangerouslySetInnerHTML={{ __html: tableOfContents ?? '' }} />
              {/* <a href="#commento">Comments</a> */}
            </div>
          </BlogSidebar>
          <div className="blog-content">
            {draft && (
              <div className="text-red-500">
                <h1>DRAFT DRAFT DRAFT</h1>
                <h2>Please do not distribute or share prior to publication</h2>
                <hr />
                <br />
              </div>
            )}
            <article dangerouslySetInnerHTML={{ __html: data.markdownRemark?.html ?? '' }} />
            {data.markdownRemark?.frontmatter?.acknowledgement && <em>{data.markdownRemark?.frontmatter?.acknowledgement}</em>}
          </div>
        </div>
      </div>
      // </IndexLayout>
    )
  }
  return (
    // <IndexLayout>
    <div className="blog">
      <SEO
        image={data.markdownRemark?.frontmatter?.image ?? undefined}
        title={data.markdownRemark?.frontmatter?.title ?? ''}
        description={data.markdownRemark?.excerpt ?? '  '}
      />
      <div className="sidebar-and-article pt-10">
        <BlogSidebar>
          <p className="leading-tight hidden sm:block">Contents:</p>
          <div className="toc">
            <div dangerouslySetInnerHTML={{ __html: tableOfContents ?? '' }} />
            {/* <a href="#commento">Comments</a> */}
          </div>
        </BlogSidebar>
        <div className="blog-content">
          <h1 className="-mt-6 leading-tight">{title}</h1>
          <h2 className="-mt-6 leading-tight italic">{subtitle}</h2>
          {draft && (
            <div className="text-red-500">
              <h1>DRAFT DRAFT DRAFT</h1>
              <h2>Please do not distribute or share prior to publication</h2>
              <hr />
              <br />
            </div>
          )}
          <article dangerouslySetInnerHTML={{ __html: data.markdownRemark?.html ?? '' }} />
          {data.markdownRemark?.frontmatter?.acknowledgement && <em>{data.markdownRemark?.frontmatter?.acknowledgement}</em>}
        </div>
      </div>
    </div>
    // </IndexLayout>
  )
}

export default PageTemplate

export const query = graphql`
  query BlogPostTemplate($slug: String!) {
    site {
      siteMetadata {
        title
        description
        author {
          name
          url
        }
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      tableOfContents(maxDepth: 3, absolute: false)
      html
      excerpt
      frontmatter {
        draft
        acknowledgement
        title
        subtitle
        image
      }
    }
  }
`
